import getConfig from 'next/config'

import type { AdyenContext } from '@grandvisionhq/payments'

const { publicRuntimeConfig } = getConfig()

const adyen = publicRuntimeConfig.adyen ?? {}

export const getAdyenConfig = (): AdyenContext => ({
  clientKey: adyen.clientKey,
  environment: adyen.environment,
})

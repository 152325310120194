import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.ES,
  locale: Locales['es-ES'],
  currency: Currencies.EUR,
  distanceUnit: DistanceUnit.km,
  dateFormat: {
    short: 'dd/MM/yyyy',
    midsize: 'dd MMMM yyyy',
    long: 'eeee d MMMM, yyyy', // Jueves 29 septiembre, 2022
  },
}

type PossibleLocales = keyof Pick<typeof Locales, 'es-ES'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'es-ES': INTL_DEFAULTS,
}

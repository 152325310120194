import { Types } from '@grandvisionhq/locations'

/*
 * Address formatting configuration
 *
 * Each format (MINIMAL, SMALL, MEDIUM, LARGE, COMPLETE), represents a
 * list of rows. Rows can be injected with values on places written like: {<valueName>}.
 * Non-word characters and spaces that remain at the end of a row after injection
 * will be trimmed and removed.
 */
const config: Types.AddressConfiguration = {
  MINIMAL: ['{streetName} {streetNumber}, {town}'],
  SMALL: [],
  MEDIUM: [],
  LARGE: ['{storeName}, {streetName} {streetNumber}, {additionalStreetInfo}, {postalCode} {town}'],
  COMPLETE: ['{streetName} {streetNumber}, {additionalStreetInfo}', '{postalCode}, {town}'],
}

export const getAddressConfig = () => config

import { CommerceToolsApi } from '@grandvisionhq/commercetools'
import type { ProductDetailProvider } from '@grandvisionhq/products'

type SpecificationConfiguration = React.ComponentPropsWithoutRef<
  typeof ProductDetailProvider
>['specificationConfiguration']

export function getSpecifications(productType: string): SpecificationConfiguration | undefined {
  if (
    CommerceToolsApi.isSunglasses(productType) ||
    CommerceToolsApi.isFrames(productType) ||
    CommerceToolsApi.isReadyReaders(productType)
  ) {
    return [
      {
        labelKey: 'products.specifications.lensColor',
        attribute: 'lensColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameColor',
        attribute: 'frameColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameMaterial',
        attribute: 'frameMaterial',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameShape',
        attribute: 'frameShape',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameType',
        attribute: 'frameType',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.gender',
        attribute: 'gender',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.roadworthiness',
        attribute: 'roadworthiness',
        labelPrefix: 'products.specifications.roadworthiness.',
      },
    ]
  }
}

import type { AppointmentFormTypes as Types } from '@grandvisionhq/appointments'

export const consents: Types.ConsentOptIn[] = [
  {
    title: 'appointments.consent.createAccount',
    value: 'createAccount',
    required: true,
  },
  {
    title: 'appointments.consent.termsConditionsAndPolicies',
    value: 'termsConditionsAndPolicies',
    required: true,
    children: [
      {
        title: 'appointments.optIn.marketingEmails',
        value: 'EMAIL_MARKETING',
      },
      {
        title: 'appointments.optIn.marketingSMS',
        value: 'MOBILE_MARKETING',
      },
    ],
  },
]

export const optIns: Types.ConsentOptIn[] = []

export const promoteInstallmentsConfiguration = {
  minimumPrice: 6000,
  numberOfInstallments: 3,
  enabledProductTypes: ['sunglasses'],
}

export const graphPromoteInstallmentsConfiguration = {
  minimumPrice: 60,
  numberOfInstallments: 3,
}
